import React from "react";
import girls from "../Assets/Images/girls1.jpg";
import skill from "../Assets/Images/skills1.jpeg";
import tree from "../Assets/Images/tree1.jpg";
import civic from "../Assets/Images/civic1.jpg";
import film from "../Assets/Images/artfilm.jpg";
import { BsFillPinFill, BsCaretRightFill } from "react-icons/bs";

function Operations() {
  return (
    <>
      <hr></hr>
      <h1 className="mt-20  text-5xl font-bold text-red-500">What We do</h1>
      <div className="card lg:card-side bg-base-100 shadow-xl mt-4 md:mt-20">
        <div className="card-body">
          <h2 className="card-title md:mb-4 mb-20 text-4xl font-bold text-red-500 justify-center">
            Gender Advocacy
          </h2>

          <ul className="features2-list text-white text-left">
            <div className="">
              <li className="features2-list-item flex items-start gap-2">
                <BsCaretRightFill className="text-red-500 mt-2" />
                <p>
                  To eradicate FGM/C practices which are rampant in Isiolo
                  County
                </p>
              </li>
              <li className="features2-list-item flex items-start gap-2">
                <BsCaretRightFill className="text-red-500 mt-2" />
                <p>
                  Raise awareness and education- the organization aims to raise
                  awareness about social justice issues within the community and
                  beyond. Through public campaigns, workshops, and educational
                  initiatives, it seeks to educate individuals about the root
                  causes of social injustice issues within the community and
                  beyond.
                </p>
              </li>
              <li className="features2-list-item flex items-start gap-2">
                <BsCaretRightFill className="text-red-500 mt-2" />
                <p>
                  Participate in community initiatives and projects for girls
                  and women impact.
                </p>
              </li>
            </div>
          </ul>
          <div className="card-actions justify-start">
            <a href="/gender" className="btn text-red-500">
              Learn more
            </a>
          </div>
        </div>
        <figure className="w-full md:w-1/2 flex-shrink-0 m-4 md:m-20">
          <img src={girls} alt="Album" />
        </figure>
      </div>

      <div
        className="card lg:card-side bg-base-100 shadow-xl mt-4 md:mt-20"
        style={{ backgroundColor: "#e20d13", borderRadius:"0px" }}
      >
        <figure className="w-full md:w-1/2 flex-shrink-0 m-4 md:m-20">
          <img src={skill} alt="Album" />
        </figure>
        <div className="card-body">
          <h2 className="card-title md:mb-4 mb-20 text-4xl font-bold text-white justify-center">
            Skills Training
          </h2>

          <ul className="features2-list text-white text-left">
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>
                Access resources and facilities for skills development and
                training.
              </p>
            </li>

            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>
                Engage in entrepreneurship programs and mentorship
                opportunities.
              </p>
            </li>
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>
                Participate in community initiatives and projects for social
                impact.
              </p>
            </li>
          </ul>
          <div className="card-actions justify-start">
            <a href="youth" className="btn text-white">
              Learn more
            </a>
          </div>
        </div>
      </div>

      <div className="card lg:card-side bg-base-100 shadow-xl mt-4 md:mt-20 ">
        <div className="card-body">
          <h2 className="card-title md:mb-4 mb-20 text-4xl font-bold text-red-500 justify-center text-left">
            Environmental Conservation and Climate Justice
          </h2>

          <ul className="features2-list text-white text-left">
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Access resources and facilities for skills development and
                training.
              </p>
            </li>

            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Engage in entrepreneurship programs and mentorship
                opportunities.
              </p>
            </li>
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Participate in community initiatives and projects for social
                impact.
              </p>
            </li>
          </ul>
          <div className="card-actions justify-start">
            <a href="climate" className="btn text-red-500">
              Learn more
            </a>
          </div>
        </div>
        <figure className="w-full md:w-1/2 flex-shrink-0 m-4 md:m-20">
          <img src={tree} alt="Album" />
        </figure>
      </div>

      <div
        className="card lg:card-side bg-base-100 shadow-xl mt-4 md:mt-20"
        style={{ backgroundColor: "#e20d13", borderRadius:"0px" }}
      >
        <figure className="w-full md:w-1/2 flex-shrink-0 m-4 md:m-20">
          <img src={film} alt="Album" />
        </figure>
        <div className="card-body">
          <h2 className="card-title md:mb-4 mb-20 text-4xl font-bold text-white justify-center">
            Art and Film
          </h2>

          <ul className="features2-list text-white text-left">
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>Photography and Videography</p>
            </li>

            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>Film Making and Script Writing</p>
            </li>
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-white mt-2" />
              <p>Story Telling</p>
            </li>
          </ul>
          <div className="card-actions justify-start">
            <button className="btn text-white">Learn more</button>
          </div>
        </div>
      </div>

      <div className="card lg:card-side bg-base-100 shadow-xl mt-4 md:mt-20">
        <figure className="w-full md:w-1/2 flex-shrink-0 m-4 md:m-20" style={{borderRadius:"0px"}}>
          <img src={civic} alt="Album" />
        </figure>
        <div className="card-body">
          <h2 className="card-title md:mb-4 mb-20 text-4xl font-bold text-red-500 justify-center">
            Civic Engagement
          </h2>

          <ul className="features2-list text-white text-left">
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Empower individuals to actively participate in local governance
                and decision-making processes.
              </p>
            </li>

            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Foster a sense of community responsibility and citizenship
                through civic education initiatives.
              </p>
            </li>
            <li className="features2-list-item flex items-start gap-2">
              <BsCaretRightFill className="text-red-500 mt-2" />
              <p>
                Advocate for equitable policies and practices that address the
                needs and concerns of all members of society.
              </p>
            </li>
          </ul>
          <div className="card-actions justify-start">
            <button className="btn text-red-500">Learn more</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Operations;
