import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faGraduationCap, faHandshake } from '@fortawesome/free-solid-svg-icons';

const Objectives = ({ objectives }) => {
  return (
    <div className="container mx-auto px-4 py-8 mb-20">
      <h1 className="text-3xl font-bold mb-10 mt-10 text-center text-white border-b border-red-500">Objectives</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
        {objectives.map((objective, index) => (
          <ObjectiveCard key={index} {...objective} />
        ))}
      </div>
    </div>
  );
};

const ObjectiveCard = ({ title, icon, description }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md flex">
      <div className="flex items-center justify-center mb-4 flex-col">
        <FontAwesomeIcon icon={icon} className="text-4xl text-red-500 mb-10" />
        <h2 className="text-xl font-bold ml-4 text-black">{title}</h2>
      </div>
      <p className="text-gray-700">{description}</p>
    </div>
  );
};

const objectivesData = [
  {
    title: "Promote the Use of Technology and Arts in Rural Communities",
    icon: faTools,
    // description: "Urbanization of the north is evident given that there is an international airport in Isiolo, a tarmacked road to Ethiopia, a proposed railway line and resort cities presenting options for youth to earn their livelihood, which is what the Talanta Mashinani programme is doing by equipping them with relevant skills. Further to these, the vocational model goes a long way in contributing to the Kenyan Government’s ‘Big Four’ agenda that strives to promote job creation for youth as well as deepen Film and Entertainment Development."
  },
  {
    title: "Provide Access to Learning and Skills Development",
    icon: faGraduationCap,
    // description: "Urbanization of the north is evident given that there is an international airport in Isiolo, a tarmacked road to Ethiopia, a proposed railway line and resort cities presenting options for youth to earn their livelihood, which is what the Talanta Mashinani programme is doing by equipping them with relevant skills. Further to these, the vocational model goes a long way in contributing to the Kenyan Government’s ‘Big Four’ agenda that strives to promote job creation for youth as well as deepen Film and Entertainment Development."
  },
  {
    title: "Create Linkages to Markets/Jobs and Create Alternative Livelihoods",
    icon: faHandshake,
    // description: "Urbanization of the north is evident given that there is an international airport in Isiolo, a tarmacked road to Ethiopia, a proposed railway line and resort cities presenting options for youth to earn their livelihood, which is what the Talanta Mashinani programme is doing by equipping them with relevant skills. Further to these, the vocational model goes a long way in contributing to the Kenyan Government’s ‘Big Four’ agenda that strives to promote job creation for youth as well as deepen Film and Entertainment Development."
  }
];

const ObjectivesComponent = () => {
  return <Objectives objectives={objectivesData} />;
};

export default ObjectivesComponent;
