import React from "react";
import geo from "../Assets/Images/geo.jpeg";

function Location() {
  return (
    <div className="hero min-h-screen flex flex-wrap justify-center items-center px-4">
      <div className="features2-content w-full md:w-1/2 md:pr-8">
        <h1 className="h2 section-title text-center md:text-left text-red-500 mb-6 md:mb-10 mt" style={{marginTop:"150px"}}>
          Our Areas of Operation
        </h1>

        <p className="section-text text-center md:text-left">
          We are currently operating in Wabera, Bula Pesa, Burat, Oldonyiro, Kinna
          and Ngaremara wards in Isiolo. With a focus on community
          development, empowerment, and sustainability, we strive to make a
          positive impact in these regions.
        </p>
      </div>
      
      <figure className="features2-banner w-full md:w-1/2 max-w-md md:max-w-none mt-20">
        <img className="w-full h-auto md:h-full object-cover" src={geo} alt="Oldonyiro Youth Centre" />
      </figure>
    </div>
  );
}

export default Location;
