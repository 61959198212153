import React from "react";
import ncm from "../Assets/Images/ncm.png";
import actionaid from "../Assets/Images/actionaid.jpg";
import mmck from "../Assets/Images/climate.jpg";
import isiolo from '../Assets/Images/isiolologo.jpg'
import ksg from '../Assets/Images/ksg-logo.png'
import activista from '../Assets/Images/activista.jpg'
import isiolotv from '../Assets/Images/isiolotv.png'
import kfcb from '../Assets/Images/kfcblogo.png'

function Partners() {
  return (
    <div className="flex flex-col items-center bg-white">
      <h1 className="text-5xl font-bold text-red-500 my-10">Our Partners</h1>
      <div className="grid grid-cols-4 gap-8 max-w-4xl mx-auto mb-20">
        <div className="flex justify-center items-center">
          <img src={ncm} alt="NCM" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={actionaid} alt="ActionAid" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={mmck} alt="MMCK" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={isiolo} alt="Isiolo" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={ksg} alt="KSG" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={activista} alt="Activista" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={kfcb} alt="Activista" className="h-24" />
        </div>
        <div className="flex justify-center items-center">
          <img src={isiolotv} alt="Activista" className="h-24" />
        </div>
      </div>
    </div>
  );
}

export default Partners;
