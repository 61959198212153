import React from 'react';
import treeplanting from '../Assets/Images/tree1.jpg';
import caravan from '../Assets/Images/caravan.jpeg';
import tank from '../Assets/Images/tank.jpeg';
import cidp from '../Assets/Images/cidp.jpeg';

function Climate() {
    const successStories = [
        {
            id: 1,
            title: 'Environmental Conservation',
            description: 'Over 2,000 trees planted in Isiolo County',
            image: treeplanting,
        },
        {
            id: 2,
            title: 'Camel Caravan',
            description: 'We joined Isiolo Camel Caravan with an aim of promoting peace and championing for climate change awareness in Isiolo and its borders',
            image: caravan,
            cols: 2,  // Set the column span to 2 for this story
        },
        {
            id: 3,
            title: 'Distribution of Water Tanks',
            description: 'We provided water tanks and carried out water piping in Waso Primary and Bulampya schools.',
            image: tank,
            cols: 2,  // Set the column span to 2 for this story
        },
        {
            id: 4,
            title: 'Climate Justice',
            description: 'We participated in the African Climate Week sessions and Advocacy',
            image: cidp,
        },
    ];

    return (
        <div className="container mx-auto py-8">
            <p className="text-xl text-left text-white mt-20 w-11/12 md:w-1/2 mx-auto mt-36 md:mt-36 mb-4 px-4 leading-relaxed">
                Our commitment to Isiolo is reflected in our dedication to conserving the environment and advocating for climate justice. These are key priorities that drive our efforts to create a better future for our community.
            </p>
            <p className="text-3xl mt-20 mb-20 text-red-500 text-center">Environmental activities</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mx-auto px-4">
                {successStories.map((story) => (
                    <div
                        key={story.id}
                        className={`card shadow-lg bg-white ${story.cols === 2 ? 'sm:col-span-2 lg:col-span-2' : ''}`}
                    >
                        <img
                            src={story.image}
                            alt={story.title}
                            className="w-full h-64 object-cover"
                        />
                        <div className="p-4">
                            <h2 className="font-bold text-xl mb-2 text-gray-700">{story.title}</h2>
                            <p className="text-black">{story.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Climate;
