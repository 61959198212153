import React from "react";
import kaskazini2 from "../Assets/Images/kaskazini2.jpeg";
import infinty from "../Assets/Images/infinity.jpeg";

function CampaignCover() {
  return (
    <div className="flex justify-center items-center min-h-screen g-20">
        
      <div className="flex flex-col md:flex-row w-full md:w-3/4 lg:w-1/2 mt-20" style={{gap:"30px", marginTop:"150px"}}>
        <div className="card bg-base-100 shadow-xl mb-8 md:mb-0 md:mr-4 ">
          <figure className="w-full md:w-50">
            <img
              src={kaskazini2}
              alt="Shoes"
              className="w-full h-full object-cover"
            />
          </figure>
          <div className="card-body w-full">
            <h2 className="card-title font-bold text-white text-2xl mb-4">Kaskazini Art Centre</h2>
            <p className="font-normal text-left text-white mb-2">
              An arts and technology Space to equip the youth and women with photography, filming and technological skills
            </p>
            <div className="card-actions justify-start">
              <a href="/campaign" className="btn text-red-500">Read more</a>
            </div>
          </div>
        </div>


        
        <div className="card bg-base-100 shadow-xl ">
          <figure className="w-full md:w-50">
            <img
              src={infinty}
              alt="Shoes"
              className="w-full h-100 object-cover"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title font-bold text-left text-white text-2xl mb-4">Infinity Endagered</h2>
            <p className="font-normal text-left text-white">
              Virtual Reality projects supported by Isiolo Advocacy Network
            </p>
            <div className="card-actions justify-start">
              <a target="blank" href="https://sites.google.com/view/jebetchepkoyo/project-page" className="btn text-red-500">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignCover;
