import React from "react";
import kaskazini2 from "../Assets/Images/kaskazini2.jpeg";
import kaskazini from "../Assets/Images/kaskaz.jpg";
import Objectives from "../components/Objectives";
import Navbar from "../components/Navbar";

function Campaign() {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div
          className="card bg-base-100 shadow-xl"
          style={{ marginBottom: "50px", width: "100%" }}
        >
          <h1 className="mt-10 mb-20 text-5xl font-bold text-red-500">
            Kaskazini Centre
          </h1>
          <figure style={{ margin: 0 }}>
            <img
              src={kaskazini}
              alt="Movie"
              style={{ width: "100%", marginLeft: "20px" }}
            />
          </figure>
          <div className="card-body">
            <p className="text-white text-left w-1/2 mx-auto mb-20">
              Kaskazini Centre is an Online and physical Training model that
              supports youth (morans and young women) in Rural areas, one of the
              key groups who provide labor(herding), and homestead security, but
              do not own any economic skill, and have low literacy level.
              Kaskazini Centre seeks to equip them with alternative
              opportunities to diversify their livelihoods, mitigate on
              continued poverty and conflict cycles especially through filming
              and photography skills.
            </p>

            <div className="max-w-3xl mx-auto text-white">
              <h1 className=" mb-10 mt-10 text-4xl font-bold text-red-500">
                Problems identified
              </h1>
              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-2 text-red-500">
                  1. Lack of Access to Formal Education
                </h3>
                <p className="text-white text-left">
                  Majority of the people living in Northern Kenya lack access to
                  formal education because of the need to take care of livestock
                  and cater for their families.
                </p>
              </div>

              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-2 text-red-500">
                  2. Impact of Increased Costs of Living
                </h3>
                <p className="text-white text-left">
                  With the increased costs of living taking place all over the
                  world the communities back in Northern Kenya are largely
                  affected due to lack of alternative livelihoods. Majority
                  being pastoralists are casualties of climate change hence
                  losing their source of income.
                </p>
              </div>

              <div className="mb-8">
                <h3 className="text-2xl font-bold mb-2 text-red-500">
                  3. Limited Access to Skills Training and Job Opportunities
                </h3>
                <p className="text-white text-left">
                  Northern Kenya and most parts of rural areas in Kenya lack
                  access to skills center to train and link them with job
                  opportunities, especially the informal sector.
                </p>
              </div>
            </div>
          </div>
          <Objectives />
        </div>
      </div>
      <div
        className=" card bg-base-100 shadow-xl mt-20"
        style={{ marginTop: 0 }}
      >
        <figure style={{ margin: 0 }}>
          <img
            src={kaskazini2}
            alt="kaskazini centre"
            style={{ width: "80%", margin: "auto" }}
          />
        </figure>
        <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Empowering Youth Through Filming and Photography
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-red-700 rounded-lg p-6 shadow-md">
                  <h3 className="text-xl font-bold mb-4 text-white">
                    Skills Training
                  </h3>
                  <p className="text-white mb-4">
                    We train youths on filming and photography as a way of
                    creating their own jobs and sharing community stories to
                    promote social awareness.
                  </p>
                </div>
                <div className="bg-red-700 rounded-lg p-6 shadow-md">
                  <h3 className="text-xl font-bold mb-4 text-white">
                    Consultancy
                  </h3>
                  <p className="text-white mb-4">
                    We offer consulting services to organisations and agencies
                    working with youths in rural areas to equip them with
                    informal knowledge and skills.
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-8">
                <div className="bg-red-700 rounded-lg p-6 shadow-md w-full sm:w-1/2">
                  <h3 className="text-xl font-bold mb-4 text-white">
                    Marketing
                  </h3>
                  <p className="text-white mb-4">
                    We provide distribution and promotional services to the
                    community project films and photography works such as access
                    to film grants, festivals, and sales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <button
      className="btn donate-btn bg-red-500 text-white text-lg font-bold py-3 px-6 rounded-lg"
      style={{
        animation: "ring 1.5s infinite",
        // Other styles here
      }}
    >
      Donate Now
    </button> */}

        </div>
      </div>
    </>
  );
}

export default Campaign;
