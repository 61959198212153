import React from "react";
import btr from "../Assets/Images/btr.jpeg";
import hod from "../Assets/Images/hod.jpeg";
import moses from "../Assets/Images/moses2.jpeg";
import award1 from "../Assets/Images/award1.jpg";
import award2 from "../Assets/Images/award2.jpg";
import award3 from "../Assets/Images/award3.jpg";
import award4 from "../Assets/Images/award4.jpg";
import award5 from "../Assets/Images/award5.jpg";

function Spotlight() {
  const films = [
    {
      id: 1,
      title: "Between The Rains",
      description:
        'Between the Rains is a coming of age story that aims to offer a traditional voice to the global conversation about change, through the lens of cultural extinction. It is a coming-of-age story of Kolei, a Turkana shepherd boy whose name translates to "Living among the goats". Unfolding over the course of a prolonged drought, viewers are pressed deeper into Kolei\'s inner conflict as he questions not only his own identity as a warrior -a path chosen for him - but also the erosion of his traditional culture which has shaped every aspect of his life. For Kolei, becoming a warrior not only defines his masculinity and identity, it is the foundation and sacred pillar of his Turkana community.',
      image: btr,
    },
    {
      id: 2,
      title: "Harvest of Doom",
      description:
        "In the arid landscapes of northern Kenya, a father's desperate struggle to provide for his family leads him into the dangerous world of sand harvesting, where environmental degradation clashes with economic survival, exposing the corrupt underbelly of power and privilege.",
      image: hod,
    },
  ];

  const awardImages = [award1, award2, award3, award4, award5]; // Array of award images

  return (
    <>
      <div className="hero min-h-screen ">
        <div className="hero-content flex-col lg:flex-row">
          <img className="w-full md:w-1/2 m-4 md:m-20" src={moses} />
          <div>
            <h1 className="text-5xl font-bold">Moses Thuranira</h1>
            <p className="py-6 text-white">
              He is a dynamic filmmaker, youth activist, and community leader
              hailing from Northern Kenya. Armed with a Bachelor&#39;s degree in
              Education Arts from Laikipia University, Moses is driven by a
              profound vision: to establish the first Northern Kenya Youth Hub
              Center. This endeavor serves as a cornerstone of his commitment to
              equipping young people with technical skills while preserving the
              region&#39;s rich arts and culture.
            </p>
          </div>
        </div>
      </div>
      {/* <p className="max-w-[50rem] md:mb-4 mb-20 mx-auto text-white">
        Moses&#39;s journey in filmmaking took a significant leap with the co-
        direction and production of the award-winning documentary &quot;Between
        The Rains,&quot; which garnered acclaim at the Tribeca Film Festival.
        Beyond the camera lens, he founded the Isiolo Advocacy Network, a
        registered organization dedicated to uplifting his community through
        grassroots initiatives. Recently elected as the chairperson of the
        Northern Kenya Filmmakers Association, Moses champions the rights and
        talents of local artists, fostering a vibrant ecosystem for creativity
        to thrive. His leadership extends internationally, with participation in
        esteemed fellowships such as the Points North Institute and Leaders for
        Leaders Africa program. Notably, Moses has been selected as a finalist
        for the prestigious Nelson Mandela Fellowship in the USA, underscoring
        his global impact as a youth advocate and social change catalyst. With
        each endeavor, Moses Thuranira continues to amplify marginalized voices,
        drive meaningful action, and inspire the next generation of changemakers
        in Northern Kenya and beyond.
      </p> */}
      <hr className="mt-10"></hr>

      <div className="container mx-auto py-8">
        <h1 className="text-white text-3xl mt-10 mb-20"> Awards and Publications</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
  {awardImages.map((image, index) => (
    <div
      key={index}
      className={`card shadow-lg bg-white transition duration-500 ease-in-out transform hover:scale-105 relative`}
      style={{ paddingBottom: "100%" }} // Maintain aspect ratio
    >
      <div className="absolute inset-0">
        <img
          src={image}
          alt={`Award ${index + 1}`}
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  ))}
</div>

      </div>

      {/* between the rains */}
      {/* <h1 className="text-white text-3xl mt-20">Award Winning Films</h1> */}
      <div className="bg-gray-100 py-20 ">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
            Award Winning Films
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {films.map((film) => (
              <div key={film.id} className="p-6 bg-white rounded-lg shadow-lg">
                <img
                  src={film.image}
                  alt={film.title}
                  className="mb-4 rounded-lg"
                />
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {film.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {film.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Spotlight;
