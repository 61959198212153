import React from 'react';
import yd from '../Assets/Images/youthday.jpeg';
import pwd from '../Assets/Images/pwd.jpeg';
import cafe from '../Assets/Images/cafe.jpg';
import gp from '../Assets/Images/gp.jpeg';
import policy from '../Assets/Images/aisha-policy.jpeg';
import { BsCaretRightFill } from 'react-icons/bs';
import { Navbar } from 'react-bootstrap';

function Youth() {
  const successStories = [
    {
      id: 1,
      title: 'International Youth Day',
      description: [
        'Marked the International youth\'s day where the youths exhibited some of their innovative products',
        'Access resources and facilities for skills development and training.',
        'Participate in community initiatives and projects for social impact.',
        'Provided platforms for artistic expression through music and art festivals.',
      ],
      image: yd,
    },
    {
      id: 2,
      title: 'International Deaf Awareness Month',
      description: [
        'Joined people living with disabilities and marked it through the celebration of International Month of The Deaf People',
        'Access resources and facilities for skills development and training.',
        'Participate in community initiatives and projects for social impact.',
        'Advocated for accessible infrastructure in public spaces.',
        'Organized seminars on deaf culture and history.',
        'Partnered with sign language interpreters for community events.',
        'Supported initiatives for equal employment opportunities.',
        'Hosted awareness campaigns to combat stigma and discrimination.',
      ],
      image: pwd,
    },
    {
      id: 3,
      title: 'Cafe Mtaani',
      description: [
        'We took part in Cafe Mtaani Talks',
        'Access resources and facilities for skills development and training.',
        'Engage in entrepreneurship programs and mentorship opportunities.',
        'Participate in community initiatives and projects for social impact.',
        'Develop leadership skills through workshops and leadership programs.',
        'Established mentorship programs to guide youth in career development.',
      ],
      image: cafe,
    },
    {
      id: 4,
      title: 'Capacity Building at Global Platform',
      description: [
        'Various youths were engaged for capacity building on different programs at global platforms',
        'Access resources and facilities for skills development and training.',
        'Engage in entrepreneurship programs and mentorship opportunities.',
        'Participate in community initiatives and projects for social impact.',
        'Gain access to networking events and industry conferences.',
        'Develop leadership skills through workshops and leadership programs.',
        'Facilitated exchange programs with international youth organizations.',
      ],
      image: gp,
    },
    {
      id: 5,
      title: 'Policies',
      description: [
        'Initiated 4 policies and succeeded to push for a youth policy in Isiolo County',
        'Advocated for inclusive education policies for marginalized groups.',
        'Collaborated with government agencies to address youth unemployment.',
        'Drafted policies to promote gender equality and women empowerment.',
        'Conducted research to inform evidence-based policy recommendations.',
      ],
      image: policy,
    },
  ];

  return (
    <>
      <Navbar />
      <div className="container mx-auto py-8 w-full">
        <p className="text-xl text-left text-white mt-20 w-11/12 md:w-1/2 mx-auto">We engage in youth related activities and engage the young people of Isiolo in advocacy and capacity building at different levels.</p>
        <hr className="mt-10 mb-20"></hr>
        <div className="grid grid-cols-1 gap-8">
          {successStories.map((story) => (
            <div key={story.id} className="flex flex-col md:flex-row shadow-lg mb-8 w-full">
              <img src={story.image} alt={story.title} className="w-full md:w-1/3 h-auto object-cover my-auto" />
              <div className="p-4 w-full md:w-2/3">
                <h2 className="text-red-500 text-xl font-bold mb-2">{story.title}</h2>
                <ul className="features2-list text-white">
                  {story.description.map((point, index) => (
                    <li key={index} className="features2-list-item flex items-start gap-2">
                      <BsCaretRightFill className="text-red-500" />
                      <p className='text-left'>{point}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Youth;
