import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "../components/Navbar";
import Operations from "../components/Operations";
import Partners from "../components/Partners";
import heroImg1 from "../Assets/Images/ian1.jpeg";
import heroImg2 from "../Assets/Images/IMG_9727.jpg";
import heroImg3 from "../Assets/Images/IMG_5720.jpg";
import heroImg4 from "../Assets/Images/sanaa.jpg";
import heroImg5 from "../Assets/Images/IMG_9737.jpg";

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1200,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    setStartAnimation(true);
  }, []);

  return (
    <>
      <Navbar />
      {/* Social Icons */}
      <div
        className="fixed top-20 right-10 flex flex-col justify-center items-center p-4 gap-4 bg-transparent shadow-md z-50 hidden lg:flex"
        style={{ top: "40%" }}
      >
        <div className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center text-white">
          <a href="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
            </svg>
          </a>
        </div>
        <div className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center text-white">
          <a href="https://www.linkedin.com/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="currentColor"
            >
              <path d="M21.1 0H2.9C1.3 0 0 1.3 0 2.9v18.2C0 22.7 1.3 24 2.9 24h18.2c1.6 0 2.9-1.3 2.9-2.9V2.9C24 1.3 22.7 0 21.1 0zM7.6 19.2H4.9V9.7h2.7v9.5h.1zm-1.3-10.3c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .9-.7 1.6-1.6 1.6zm15.2 10.3h-2.7v-5c0-1.2-.4-2-1.3-2-1.3 0-2 1.1-2 2.2v4.8H10V9.7h2.6v1.2c.3-.4 1-1 2.4-1 1.8 0 3 1.2 3 3.7v6.6h-.1z" />
            </svg>
          </a>
        </div>
        <div className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center text-white">
          <a href="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-current"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
            </svg>
          </a>
        </div>
      </div>
      <Slider {...settings}>
        {[heroImg1, heroImg2, heroImg3, heroImg4, heroImg5].map(
          (img, index) => (
            <div key={index}>
              <div className="hero min-h-screen">
                <img
                  src={img}
                  alt={`Slide ${index + 1}`}
                  className="object-cover w-full h-full"
                />
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content text-center text-neutral-content">
                  <div className="">
                    <h1 className="mb-5 text-4xl sm:text-6xl font-bold text-white shadow-lg ">
                      ISIOLO ADVOCACY NETWORK
                    </h1>
                    <p className="mb-5 text-white font-bold text-xl sm:text-2xl mx-auto max-w-md mt-5 shadow-lg ">
                      Empowering Voices, Changing Lives: <br />
                      Join our registered Community-Based Organization in
                      advocating for social and climate justice in Isiolo
                      County.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </Slider>
      {/* reach */}
      <div className="mt-0 mb-20">
        <div className="stats stats-vertical lg:stats-horizontal shadow">
          <div className="stat">
            <div className="stat-title text-red-500">Capacity Building</div>
            <div className="stat-value">
              {startAnimation && (
                <CountUp start={0} end={2000} duration={20} separator="," />
              )}{" "}
              Youths
            </div>
          </div>
          <div className="stat">
            <div className="stat-title text-red-500">Policy Advocacy</div>
            <div className="stat-value">
              {startAnimation && <CountUp start={0} end={4} duration={10} />}
            </div>
            <div className="stat-desc">In 2023</div>
          </div>
          <div className="stat">
            <div className="stat-title text-red-500">Digital Advocacy</div>
            <div className="stat-value">
              {startAnimation && (
                <CountUp start={0} end={9000000} duration={20} separator="," />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Mission and Vision */}
      <div className="py-20" style={{ backgroundColor: "#e20d13" }}>
  <div className="container mx-auto px-4">
    <div className="grid grid-cols-1 gap-10 md:grid-cols-2 md:gap-20">
      <div className="mx-auto w-full max-w-lg md:max-w-none">
        <h2 className="text-4xl font-bold text-white mb-5 text-left">Our Vision</h2>
        <p className="text-white text-lg leading-relaxed text-left">
          To become a leading force in advocating for social and climate
          justice, empowering communities, and fostering sustainable
          development in Isiolo County.
        </p>
      </div>

      <div className="mx-auto w-full max-w-lg md:max-w-none">
        <h2 className="text-4xl font-bold text-white mb-5 text-left">Our Mission</h2>
        <p className="text-white text-lg leading-relaxed text-left">
          To build the capacity of local communities, promote inclusive
          participation, and influence policy changes for a just and
          equitable society, addressing social and environmental
          challenges in Isiolo County.
        </p>
      </div>
    </div>
  </div>
</div>

      <Operations />
      <Partners />
      {/* Reach Out */}
      <div className="relative isolate overflow-hidden py-10 sm:py-14 lg:py-20 flex justify-center items-center">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Subscribe to our newsletter.
            </h2>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
