import React from "react";
import "../components/Us.css";
import banner from "../Assets/Images/cidp.jpeg";
import banner2 from "../Assets/Images/cafe.jpg"
import banner3 from "../Assets/Images/girls2.jpg"
import banner4 from '../Assets/Images/env.jpg'
import banner5 from '../Assets/Images/girls3.jpg'

function Us() {
  return (
    <div id="about" className="hero min-h-screen text-white" >
      <div id="About" className="AboutSection container mx-auto py-8" style={{marginTop:"20px"}}>
        <br /> <br />
        <section className="aboutSection">
          <h2 className="h2 text-center section-title text-red-500" style={{marginTop:"100px"}}>
            Who We are
          </h2>

          <p className="section-text text-center">
            We are a registered Community-Based Organization (CBO) operating in
            Isiolo County, advocating for the rights of marginalized groups such
            as youth and women, with a particular focus on social and climate
            justice.
          </p>
          <hr className="mt-20"></hr>
          <div className="container1 mt-20">
            <h2 className="h2 about-title h2 text-center section-title text-red-500 md:w-1/4 md:text-right md:mr-8">
              Our Origin Story
            </h2>
            <div className="about-content w-full mb-20 flex flex-col md:flex-row items-center justify-center md:items-stretch">
              <p className="about-text w-full md:w-3/4 text-left">
                Our journey began as a grassroots movement called Activista,
                driven by the desire to bring about positive change in our
                community. Recognizing the systemic barriers faced by
                marginalized groups, particularly youth and women, we set out to
                advocate for fairness and environmental sustainability. As
                Activista gained momentum, we realized the need for
                formalization to amplify our impact. Thus, the Isiolo Advocacy
                Network was born—a registered Community-Based Organization
                dedicated to championing social and climate justice.
              </p>
              <img
                className="w-full h-full md:w-1/2 ml-0 md:ml-8 mb-4 md:mb-0"
                src={banner}
                alt="Banner"
              />
            </div>

            <ul className="about-list grid gap-4 md:grid-cols-2 lg:grid-cols-4 mt-20">
              <li>
                <div className="about-card">
                  <div className="card-icon">
                    <img
                      src={banner2}
                      alt="svg"
                      className="w-12 h-12 md:w-10 md:h-10"
                    />
                  </div>

                  <h3 className="h3 card-title text-red-500"> Youth Empowerment</h3>

                  <p className="card-text text-left" style={{marginLeft:"0px"}}>
                    We engage in programs and initiatives aimed at empowering youth
                    through skill-building, entrepreneurship, and advocacy.
                  </p>
                </div>
              </li>

              <li>
                <div className="about-card">
                  <div className="card-icon">
                    <img
                      src={banner3}
                      alt="svg"
                      className="w-12 h-12 md:w-10 md:h-10"
                    />
                  </div>

                  <h3 className="h3 card-title text-red-500"> Women Empowerment</h3>

                  <p className="card-text text-left" style={{marginLeft:"0px"}}>
                    We advocate for gender equality and provide support for women's
                    education, health, and economic empowerment.
                  </p>
                </div>
              </li>

              <li>
                <div className="about-card">
                  <div className="card-icon">
                    <img
                      src={banner4}
                      alt="svg"
                      className="w-12 h-12 md:w-10 md:h-10"
                    />
                  </div>

                  <h3 className="h3 card-title text-red-500"> Environmental Conservation</h3>

                  <p className="card-text text-left" style={{marginLeft:"0px"}}>
                    We promote sustainable practices and initiatives to protect the
                    environment and mitigate the effects of climate change.
                  </p>
                </div>
              </li>

              <li>
                <div className="about-card">
                  <div className="card-icon">
                    <img
                      src={banner5}
                      alt="svg"
                      className="w-12 h-12 md:w-10 md:h-10"
                    />
                  </div>

                  <h3 className="h3 card-title text-center text-red-500"> Advocacy and Research</h3>

                  <p className="card-text text-left text-white" style={{marginLeft:"0px"}}>
                    We conduct research and advocacy campaigns to address social
                    and environmental issues and drive policy change.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <br />
      </div>
    </div>
  );
}

export default Us;
