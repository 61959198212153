import React from "react";
import girl from "../Assets/Images/girls2.jpg";
import pwd from "../Assets/Images/pwd.jpeg";
import award from "../Assets/Images/award6.jpg";
import { BsCaretRightFill } from "react-icons/bs";

function Gender() {
  const successStories = [
    {
      id: 1,
      title: "Gender Advocacy",
      description: [
        "More than 1,000 young girls accessed Menstrual Dignity Kits",
        "Fight and advocacy against FGM",
      ],
      image: girl,
    },
    {
      id: 2,
      title: "Feminists Hangout",
      description: [
        "Monthly Feminists Hangout to address Gender related issues",
        "Open space for young ladies to share the stories and encourage one another",
      ],
      image: pwd,
    },
  ];

  return (
    <div className="container mx-auto pt-24 md:pt-32 lg:pt-40 py-8">
      <p className="text-white mt-20 w-11/12 md:w-1/2 mx-auto text-left mb-20">
        Gender advocacy is not just about promoting equality; it's about creating
        a society where every individual, regardless of gender, has the opportunity
        to thrive. By championing gender equality, we not only dismantle barriers
        and biases but also pave the way for a more inclusive and prosperous
        future. Our commitment to gender advocacy extends beyond rhetoric; it's a
        tangible effort to empower women, challenge stereotypes, and foster
        environments where everyone can reach their full potential. Together, we're
        reshaping norms, amplifying voices, and building a world where gender
        equity is not just an ideal but a reality.
      </p>

      <p className="text-3xl text-center text-red-500 mb-20">
        Gender advocacy success stories
      </p>

      <div className="grid grid-cols-1 gap-8">
        {successStories.map((story) => (
          <div key={story.id} className="flex flex-col md:flex-row shadow-lg mb-8">
            <img
              src={story.image}
              alt={story.title}
              className="w-full md:w-1/2 h-auto object-cover"
            />
            <div className="p-4 w-full md:w-2/3">
              <h2 className="text-red-500 text-xl font-bold mb-2">
                {story.title}
              </h2>
              <ul className="features2-list text-white">
                {story.description.map((point, index) => (
                  <li key={index} className="features2-list-item flex items-start gap-2">
                    <BsCaretRightFill className="text-red-500" />
                    <p className="text-left">{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full mx-auto max-w-xl">
      <div className="text-center mt-20 mb-10">
          <p className="text-white font-bold text-lg">
            We were awarded a trophy by ActionAid, The Girl Generation as a
            recognition of contributing towards ending FGM
          </p>
        </div>
        <img
          className="mx-auto w-full h-auto max-h-100 object-cover mb-8"
          src={award}
          alt="Award"
        />
        
      </div>
    </div>
  );
}

export default Gender;
