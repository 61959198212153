import React from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm("service_uyfnfze", "template_khni0qb", e.target, {
        publicKey: "0xVUzBCp0zWTJni4N",
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response);
        },
        (error) => {
          console.log("FAILED...", error);
          // Handle error
        }
      );
    e.target.reset();
  }

  return (
    <div className="">
      {/* Top container with tagline and backgroundImage */}
      <div className="relative bg-base-100">
        {/* Image section */}
        <section className="w-full h-[40vh] lg:h-[55vh]  bg-cover bg-center brightness-50"></section>

        {/* Tagline division */}
        <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white align-center z-10 mx-auto mt-5">
          <h1
            className="text-3xl md:text-5xl font-bold text-center "
            style={{ marginTop: "150px" }}
          >
            CONTACT US
          </h1>
          <p className="text-sm md:text-lg">
            Get in touch with us for social and environmental justice.
          </p>
        </div>
      </div>

      {/* Bottom relative container */}
      <div className="w-full h-[1200px] md:h-[60vh] lg:h-[130vh] bg-gray-300 relative">
        {/* Division with floating form */}
        <div className="absolute -top-[3%] md:-top-[10%] left-1/2 -translate-x-1/2 grid grid-cols-1 md:grid-cols-3 h-fit w-4/5 md:w-[90%] lg:w-4/5 rounded shadow overflow-hidden text-white">
          {/* Form / left div */}
          <div className="p-2 md:p-4 h-full bg-gray-800 col-span-2">
            <form onSubmit={sendEmail}>
              {/* Form top part containing mail icon and heading */}
              <div className="flex flex-col md:flex-row justify-around items-start md:items-center pt-8 p-4">
                {/* Heading */}
                <h2 className="text-2xl md:text-3xl font-semibold">
                  Send Us A Message
                </h2>

                {/* Mail SVG icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mail-forward"
                  width="33"
                  height="33"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#fff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                  <path d="M3 6l9 6l9 -6" />
                  <path d="M15 18h6" />
                  <path d="M18 15l3 3l-3 3" />
                </svg>
              </div>

              {/* Bottom form with input fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-6 px-4 md:py-12 md:px-8 text-sm">
                {/* Name input */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold">
                    Name <span className="text-red-500">&#42;</span>
                  </label>
                  <input
                    className="border-[1px] border-white bg-gray-800 p-2 rounded-md"
                    placeholder="Enter Your Name"
                    required
                    name="name"
                    type="text"
                  />
                </div>

                {/* Email input */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold">
                    Email <span className="text-red-500">&#42;</span>
                  </label>
                  <input
                    className="border-[1px] border-white bg-gray-800 p-2 rounded-md"
                    placeholder="Enter Your Email"
                    required
                    name="email"
                    type="email"
                  />
                </div>

                {/* Phone number input */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold">
                    Phone <span className="text-red-500">&#42;</span>
                  </label>
                  <input
                    className="border-[1px] border-white bg-gray-800 p-2 rounded-md"
                    placeholder="Enter Your Phone Number"
                    required
                    name="phone"
                    type="tel"
                  />
                </div>

                {/* Subject input */}
                <div className="flex flex-col gap-1">
                  <label className="font-semibold">
                    Subject <span className="text-red-500">&#42;</span>
                  </label>
                  <input
                    className="border-[1px] border-white bg-gray-800 p-2 rounded-md"
                    placeholder="Enter Your Subject"
                    required
                    name="subject"
                    type="text"
                  />
                </div>

                {/* Message input */}
                <div className="flex flex-col gap-1 md:col-span-2">
                  <label className="font-semibold">
                    Message <span className="text-red-500">&#42;</span>
                  </label>
                  <textarea
                    className="border-[1px] border-white bg-gray-800 p-2 rounded-md"
                    placeholder="Enter Your Message"
                    required
                    name="message"
                    rows="4"
                  ></textarea>
                </div>
              </div>
              {/* Submit button div */}
              <div className="flex items-center justify-center md:justify-end py-4 px-8">
                {/* Submit button */}
                <button
                  type="submit"
                  className="py-2 px-4 md:py-4 md:px-6 bg-gray-800 rounded-md border-2 border-white flex items-center gap-2 hover:scale-95 transition-all"
                >
                  <span className="text-xl">Submit</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-brand-telegram"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#fff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" />
                  </svg>
                </button>
              </div>
            </form>
          </div>

          {/* Right div */}
          <div className="py-6 px-4 h-[400px] md:h-full bg-red-800 grid grid-cols-1 grid-rows-5">
            {/* Heading tag */}
            <h2 className="text-xl lg:text-2xl text-center md:text-start font-semibold">
              Contact Information
            </h2>

            {/* Email and icon */}
            <div className="row-span-4 flex flex-col items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-mail-share"
                width="35"
                height="35"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                <path d="M3 6l9 6l9 -6" />
                <path d="M16 22l5 -5" />
                <path d="M21 21.5v-4.5h-4.5" />
              </svg>
              <span>info@isioloadvocacynetwork.org</span>
            </div>



            <div className="flex">
              <a href="https://x.com/Isioloadvocacy">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                </svg>
              </a>
              <a href="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M21.1 0H2.9C1.3 0 0 1.3 0 2.9v18.2C0 22.7 1.3 24 2.9 24h18.2c1.6 0 2.9-1.3 2.9-2.9V2.9C24 1.3 22.7 0 21.1 0zM7.6 19.2H4.9V9.7h2.7v9.5h.1zm-1.3-10.3c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .9-.7 1.6-1.6 1.6zm15.2 10.3h-2.7v-5c0-1.2-.4-2-1.3-2-1.3 0-2 1.1-2 2.2v4.8H10V9.7h2.6v1.2c.3-.4 1-1 2.4-1 1.8 0 3 1.2 3 3.7v6.6h-.1z" />
                </svg>
              </a>
              <a href="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
