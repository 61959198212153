import React from "react";

function Footer() {
  return (
    <footer className="footer p-6 md:p-10 lg:p-14 xl:p-20 bg-base-300 text-base-content flex flex-col md:flex-row justify-center gap-4 md:gap-20">
  {/* Address */}
  <div className="section-text">
    <h6 className="footer-title text-xl font-bold text-red-500">Address</h6>
    <p className="">Isiolo Youth Innovation Center</p>
    <p className="">Isiolo, Kenya</p>
    <p>Email: <span className="underline">info@isioloadvocaynetwork.org </span></p>
  </div>

  {/* Services */}
  <nav className="section-text">
    <h6 className="footer-title text-xl font-bold text-red-500">Services</h6> 
    <a className="link link-hover">Advocacy</a>
    <a className="link link-hover">Conservation</a>
    <a className="link link-hover">Art</a>
    <a className="link link-hover">Technology</a>
  </nav> 
  
  {/* Company */}
  <nav className="section-text">
    <h6 className="footer-title text-xl font-bold text-red-500">Company</h6> 
    <a href="/" className="link link-hover">Home</a>
    <a href="/us" className="link link-hover">About us</a>
    <a href="contact" className="link link-hover">Contact</a>
    <a href="campaign" className="link link-hover">campaign</a>
  </nav> 
  
  {/* Social */}
  <nav className="section-text">
    <h6 className="footer-title text-xl font-bold text-red-500">Social</h6> 
    <div className="grid grid-flow-col gap-4">
      <a href="https://x.com/Isioloadvocacy" target="blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
      <a href=""><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="currentColor"
        >
          <path d="M21.1 0H2.9C1.3 0 0 1.3 0 2.9v18.2C0 22.7 1.3 24 2.9 24h18.2c1.6 0 2.9-1.3 2.9-2.9V2.9C24 1.3 22.7 0 21.1 0zM7.6 19.2H4.9V9.7h2.7v9.5h.1zm-1.3-10.3c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6c0 .9-.7 1.6-1.6 1.6zm15.2 10.3h-2.7v-5c0-1.2-.4-2-1.3-2-1.3 0-2 1.1-2 2.2v4.8H10V9.7h2.6v1.2c.3-.4 1-1 2.4-1 1.8 0 3 1.2 3 3.7v6.6h-.1z" />
        </svg></a>
      <a href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
    </div>
  </nav>
</footer>

  );
}

export default Footer;
