import React, { useEffect } from "react";
import Spotlight from "../pages/Spotlight";
import btr from "../Assets/Images/film-prod.jpg";
import infinity from "../Assets/Images/infinity.jpeg";
import art from "../Assets/Images/art.jpg";
import mentor from "../Assets/Images/screening.jpg";
import screening from "../Assets/Images/screening2.jpg";

const achievementData = [
  {
    image: btr,
    title: "Film Production",
    description:
      "We produce impactful films aimed at positively influencing society.",
  },
  {
    image: art,
    title: "Artistic Fridays",
    description:
      "Artistic Fridays provide a platform in Isiolo for creatives to showcase and refine their talents while fostering networking opportunities.",
  },
  {
    image: infinity,
    title: "Virtual Reality",
    description:
      'We are supporting the "Endangered Infinity" virtual reality project, dedicated to conserving endangered species through innovative technology.',
  },
  {
    image: mentor,
    title: "Talent Search and Mentorship",
    description:
      "Our initiative bridges cultural divides and cultivates understanding among communities through talent discovery and mentorship programs.",
  },
  {
    image: screening,
    title: "Community Screening",
    description:
      "We engage local communities through film screenings, fostering dialogue, and promoting cultural exchange to inspire collective action.",
  },

  // {
  //   image: 'path_to_image_6.jpg',
  //   title: 'Art Aficionado',
  //   description: 'Curated a successful art exhibition showcasing local talent.',
  // },
  // {
  //   image: 'path_to_image_7.jpg',
  //   title: 'Innovator\'s Vision',
  //   description: 'Introduced a groundbreaking idea and saw it implemented successfully.',
  // },
];

function Art() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <header className="text-center mb-20 sm:mt-48 md:mt-48 lg:mt-28 xl:mt-36">
          <h1 className="text-3xl font-bold text-red-500 mb-4">
            Art and Tech Projects
          </h1>
        </header>

        <main>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
            {achievementData.map((achievement, index) => (
              <div
                key={index}
                className="flex flex-col items-center p-4 border border-red-500 rounded-lg"
              >
                <img
                  src={achievement.image}
                  alt={achievement.title}
                  className="mb-2"
                  style={{
                    width: "400px",
                    height: "350px",
                    objectFit: "cover",
                  }}
                />
                <h2 className="text-lg font-semibold text-red-500 mb-2">
                  {achievement.title}
                </h2>
                <p className="text-sm text-white mb-4">
                  {achievement.description}
                </p>
              </div>
            ))}
          </div>
        </main>
      </div>
      <hr className="w-1/2 mx-auto border-t-2 border-gray-300 my-8" />
      <p className="text-3xl mt-20 mb-0 font-bold text-red-500">Featured Stories</p>
      <Spotlight />
    </>
  );
}

export default Art;
